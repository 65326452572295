import React, { useState } from 'react'
import banner from "../assets/form_banner.jpg";
import cars from "../assets/form_cars.png";
import OneWay from './OneWay';
import Roundtrip from './Roundtrip';
const BookingForm = () => {

    const [fromChange, setFormChange] = useState(false);

    const divStyle = {
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
    };
  
    function changeFroms(e) {
        if(e.target.value == 'oneway'){
            setFormChange(false)
        }else{
            setFormChange(true)
        }
    }
    return (
        <div className='form_bg relative bg-fixed' style={divStyle} >
            <div className='container py-28 mx-auto px-0 sm:px-4 md:px-8 lg:px-16 relative z-10'>
                <div className='grid grid-cols-1  md:grid-cols-1 lg:grid-cols-2 gap-y-6'>
                    <div className='w-full md:w-4/5 text-white'>
                        <img src={cars} alt="cars" className='w-[250px]' />
                        <div className=' text-6xl text-white font-bold mt-4'>One Way Drop Taxi Booking</div>
                        <div className='text-5xl mt-5 font-semibold'>at Safe Taxis - Taxi Services</div>
                        <div className='mt-4 text-lg opacity-75'> A Hassle-Free Journey all over Tamil Nadu, Andhra, Telangana, Karnataka and Kerala</div>
                        <div className=' text-white font-bold text-lg mt-10'>
                            <a href="tel:+919361265553" className=' rounded-md bg-[#e30d16] hover:bg-black transition-all ease-linear px-3 py-2'>Call +919361265553</a>
                        </div>
                    </div>
                    <div className=' bg-white pb-4 rounded-lg px-4' id='book_taxi_form'>
                        <div className='container mx-auto '>
                            <h3 className=' mt-4 font-bold text-[30px] sm:text-[40px] md:text-[50px] text-center'>Book One Way Taxi Service </h3>
                            <p className='sm:mt-2 md:mt-4 text-center '> We are providing one way taxi, outstation cabs, drop taxi and airport taxi service.</p>
                            <div className='pt-6'>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 justify-items-center py-3 pb-5 bg-white rounded-md items-center'>
                                    <label className={`font-semibold hover:text-[#000] py-2 ${fromChange ? '' : 'text-white form_shadow'} cursor-pointer`} htmlFor="oneway" onClick={changeFroms}>
                                        One Way
                                        <input type="radio" name='type' id='oneway' value="oneway" className='w-0 h-0' />
                                    </label>
                                    <label className={`font-semibold hover:text-[#000]  py-2 cursor-pointer  ${fromChange ? ' form_shadow' : ''}`} htmlFor="round" onClick={changeFroms}>
                                        Round Trip
                                        <input type="radio" name='type' id='round' value="round" className='w-0 h-0' />
                                    </label>
                                </div>
                            </div>
                            {
                                !fromChange ? (<OneWay />) : (<Roundtrip />)
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingForm