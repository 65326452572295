import React from 'react'

const NavList = ({ ul_class, li_class, close_click }) => {
    return (
        <ul className={`${ul_class}`}>
            <li className={`${li_class}`}><a href="/" onClick={close_click ? close_click : close_click}>Home</a></li>
            <li className={`${li_class}`}><a href="#pop_routes" onClick={close_click ? close_click : close_click}>Popular Routes</a></li>
            <li className={`${li_class}`}><a href="#cars" onClick={close_click ? close_click : close_click}>Cars / Tarrif</a></li>
            <li className={`${li_class}`}><a href="#footer_contact" onClick={close_click ? close_click : close_click}>Contact</a></li>
            <li ><a href="#book_taxi_form" className='bg-[#e30d16] px-4 py-2 text-white rounded-3xl'  onClick={close_click ? close_click : close_click}>Book Now</a></li>
        </ul>
    )
}

export default NavList